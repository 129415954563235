import draggable from "vuedraggable";
<template>
  <v-app>
    <v-row class="pa-4" justify="space-between">
      <v-col cols="12" v-if="isBusy">
        <v-skeleton-loader v-bind="attrs" type="article, actions"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" v-else>
        <v-card flat rounded>
          <v-card-title class="d-flex flex justify-content-between">
            {{ lesson_plan.title || "N/A" }}
          </v-card-title>
          <v-card-subtitle>
            Course: <strong> {{ lesson_plan.course_title }} </strong> | Batch:
            <strong> {{ lesson_plan.academic_year_title || "N/A" }} </strong> | Classroom
            : <strong>{{ lesson_plan.academic_class_title || "N/A" }}</strong> | Total
            Assignments : <strong>{{ lesson_plan.total_assignment || "0" }}</strong> |
            Total Lesson :
            <strong>{{ lesson_plan.total_lessons || "0" }}</strong>
          </v-card-subtitle>
          <v-card-text>
            <v-progress-linear
              v-model="lesson_plan.lesson_completed"
              color="green"
              height="5"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4" v-if="isLessonBusy">
        <v-skeleton-loader
          v-bind="attrs"
          type="article,list-item-three-line, actions"
        ></v-skeleton-loader>
      </v-col>
      <v-col md="4" cols="12" class="d-none d-md-block" v-else>
        <v-card flat rounded>
          <v-card-text>
            <v-treeview
              v-if="lesson_plan_topics && lesson_plan_topics.length"
              style="overflow-y: auto"
              :items="lesson_plan_topics"
              item-children="lessons"
              value="select"
              dense
              activatable
              hoverable
              item-key="title"
              item-text="title"
              color="success"
              open-all
              transition
            >
              <template v-slot:prepend="{ item, open, leaf }">
                <v-icon v-if="item.completed" color="green">
                  {{ open ? "mdi-check-circle" : "mdi-check-circle" }}
                </v-icon>
                <v-icon
                  color="blue"
                  v-else-if="
                    lesson_plan.current_lesson && lesson_plan.current_lesson == item.id
                  "
                >
                  {{ open && leaf ? "mdi-notebook-outline " : "mdi-pin" }}
                </v-icon>
                <v-icon v-else>
                  {{ open ? "mdi-book-open-page-variant" : "mdi-notebook-outline" }}
                </v-icon>
              </template>
              <template v-slot:label="{ item, open, leaf }">
                <div
                  @click="check(item, leaf)"
                  class="my-auto overflow-visible cursor-pointer"
                >
                  {{ item.title }}
                </div>
              </template>
            </v-treeview>
            <div v-else>
                No unit added
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="8" v-if="loading">
        <v-skeleton-loader v-bind="attrs" type="article, actions"></v-skeleton-loader>
      </v-col>
      <v-col md="8" cols="12" class=" " v-else>
        <v-navigation-drawer
          v-if="this.$vuetify.breakpoint.name == 'xs'"
          v-model="drawer"
          absolute
          temporary
          class="d-md-none"
        >
          <v-treeview
            v-if="lesson_plan_topics && lesson_plan_topics.length"
            style="overflow-y: auto"
            :items="lesson_plan_topics"
            item-children="lessons"
            value="select"
            dense
            activatable
            hoverable
            item-key="title"
            item-text="title"
            color="success"
            open-all
            transition
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="item.completed" color="green">
                {{ open ? "mdi-check-circle" : "mdi-check-circle" }}
              </v-icon>
              <v-icon
                color="blue"
                v-else-if="
                  lesson_plan.current_lesson && lesson_plan.current_lesson == item.id
                "
              >
                {{ open ? "mdi-pin" : "mdi-pin" }}
              </v-icon>
              <v-icon v-else>
                {{ open ? "mdi-book-open-page-variant" : "mdi-notebook-outline" }}
              </v-icon>
            </template>
            <template v-slot:label="{ item, open, leaf }">
              <div
                @click="check(item, leaf)"
                class="my-auto overflow-visible cursor-pointer"
              >
                {{ item.title }}
              </div>
            </template>
          </v-treeview>
        </v-navigation-drawer>
        <v-scroll-y-transition mode="out-in">
          <v-card v-if="!lessonSummary">
            <v-card-text> No lesson added </v-card-text>
          </v-card>
          <!--                    Lesson Summary Start-->
          <v-card
            v-if="lessonView && lessonSummary"
            :key="lessonSummary.id"
            flat
            min-height="400px"
            height="100%"
          >
            <v-card-text>
              <div class="d-flex flex justify-content-between">
                <div>
                  <v-btn @click="toogle" class="mx-2 d-md-none" small flat depressed>
                    <v-icon dark> fas fa-list </v-icon>
                  </v-btn>
                  <h2>
                    {{ lessonSummary.topic.title || "N/A" }}

                    <v-chip
                      v-if="
                        lesson_plan.current_lesson &&
                        lesson_plan.current_lesson == lessonSummary.id
                      "
                      x-small
                    >
                      Currently Reading
                    </v-chip>

                    <v-icon v-if="lessonSummary.completed" color="green">
                      mdi-check-circle
                    </v-icon>

                    <small class="font-size-sm">
                      {{ lessonSummary.completed_at | moment("from", "now") }}
                    </small>
                  </h2>
                  <span
                    >Lecture hour: {{ lessonSummary.topic.lecture_hour || "N/A" }}</span
                  >
                </div>
              </div>

              <h3 class="text-h5 mb-2">
                {{ lessonSummary.title }}
                <span v-if="edit">
                  <i
                    class="flaticon-add ml-2 cursor-pointer"
                    @click="__addLesson(lessonSummary.topic.id, lessonSummary.id)"
                    v-b-tooltip.hover
                    title="Add New"
                  ></i>
                  <i
                    class="flaticon-edit ml-2 cursor-pointer"
                    @click="__editLesson(lessonSummary.topic.id, lessonSummary.id)"
                    v-b-tooltip.hover
                    title="Edit"
                  ></i>
                  <i
                    class="fas fa-trash ml-2 cursor-pointer"
                    @click="__deleteTopicLesson(lessonSummary.topic_id, lessonSummary.id)"
                    v-b-tooltip.hover
                    title="Delete"
                  ></i>
                </span>
              </h3>
              <div class="ml-2 mb-2">
                <p v-html="lessonSummary.description"></p>
              </div>

              <!--                            Lesson Wise Activities Or Resources-->
              <div
                class="card-body"
                v-if="
                  lessonSummary.activities_resources &&
                  lessonSummary.activities_resources.length
                "
              >
                -
                <v-divider></v-divider>
                <h3><b>Unit activities & resources for:</b> {{ lessonSummary.title }}</h3>
                <v-list three-line>
                  <template v-for="(item, index) in lessonSummary.activities_resources">
                    <v-list-item :key="item.id">
                      <v-list-item-avatar>
                        <v-img :src="item.activity.full_image_path"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          <div v-if="checkType(item.activity.type) == 'url'">
                            <a :href="item.url" target="_blank">
                              <strong> {{ item.title }}</strong>
                            </a>
                            <span v-if="edit">
                              <i
                                class="flaticon-edit ml-4 cursor-pointer"
                                @click="
                                  __editResourceLesson(
                                    lessonSummary.id,
                                    item.activity.id,
                                    item.id
                                  )
                                "
                                v-b-tooltip.hover
                                title="Edit"
                              ></i>
                              <i
                                class="fas fa-trash ml-4 cursor-pointer"
                                @click="__deleteResourceLesson(lessonSummary.id, item.id)"
                                v-b-tooltip.hover
                                title="Delete"
                              ></i>
                            </span>
                          </div>

                          <strong v-else>
                            {{ item.title }}
                            <span v-if="edit">
                              <i
                                class="flaticon-edit ml-4 cursor-pointer"
                                @click="
                                  __editResourceLesson(
                                    lessonSummary.id,
                                    item.activity.id,
                                    item.id
                                  )
                                "
                                v-b-tooltip.hover
                                title="Edit"
                              ></i>
                              <i
                                class="fas fa-trash ml-4 cursor-pointer"
                                @click="__deleteResourceLesson(lessonSummary.id, item.id)"
                                v-b-tooltip.hover
                                title="Delete"
                              ></i>
                            </span>
                          </strong>
                        </v-list-item-title>
                        <v-list-item-content>
                          <a
                            v-if="item.type != 'assignment' && item.type != 'quiz' && item.type != 'video' && item.type != 'external_links' && item.type != 'book_url'"
                            :href="item.path.real"
                            target="_blank"
                            > Download </a>

                          <a
                            v-if="item.type == 'quiz'"
                            @click="startQuiz(item.id)"
                            target="_blank"
                            >Start quiz</a
                          >
                          <router-link
                            :to="{
                              name: 'assignment-summary',
                              params: { assignment_id: item.assignment.id },
                            }"
                            custom
                            v-if="item.type == 'assignment'"
                          >
                            View assignment
                          </router-link>
                        </v-list-item-content>
                        <v-list-item-subtitle
                          v-html="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </div>
              <div v-else></div>

              <!--                            Lesson Wise Activities Or Resources-->
              <v-divider></v-divider>

              <div class="card-body" v-if="lessonSummary.topic.activity_resources.length">
                <h3><b>Topic activities & resources:</b></h3>
                <v-list three-line>
                  <template
                    v-for="(item, index) in lessonSummary.topic.activity_resources"
                  >
                    <v-list-item dense :key="item.id">
                      <v-list-item-avatar rounded>
                        <v-img :src="item.activity.full_image_path"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          <div v-if="checkType(item.activity.type) == 'url'">
                            <a :href="item.url" target="_blank">
                              <strong> {{ item.title }}</strong>
                            </a>
                          </div>

                          <strong v-else>
                            {{ item.title }}
                          </strong>
                        </v-list-item-title>
                        <v-list-item-content>
                          <a
                            v-if="item.type != 'assignment' && item.type != 'quiz'"
                            :href="item.path.real"
                            target="_blank"
                            >Download </a
                          >
                          <router-link
                            :to="{
                              name: 'assignment-summary',
                              params: { assignment_id: item.assignment.id },
                            }"
                            custom
                            v-if="item.type == 'assignment'"
                          >
                            View Assignment
                          </router-link>
                          <router-link
                            v-if="item.type == 'assignment'"
                            :to="{
                              name: 'assignment-submission',
                              params: { assignment_id: item.assignment.id },
                            }"
                            class="navi-link"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-eye"></i>
                            </span>
                            <span class="navi-text">View Submissions</span>
                          </router-link>
                        </v-list-item-content>
                        <v-list-item-subtitle
                          class="list-content-desc"
                          v-html="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
          <!--                    Lesson Summary End-->

          <!--                    Unit Summary View Start-->

          <v-card v-if="!lessonView">
            <v-btn @click="toogle" class="mx-2 d-md-none" small flat depressed>
              <v-icon dark> fas fa-list </v-icon>
            </v-btn>
            <v-card-text>
              <div>
                <h2>{{ selectedUnit.title || "N/A" }}</h2>
                <span> Lecture hour: {{ selectedUnit.lecture_hour || "N/A" }}</span>
              </div>
              <v-expansion-panels flat focusable v-if="lessons.length" multiple>
                <v-expansion-panel v-for="(topic_lesson, i) in lessons" :key="i">
                  <v-expansion-panel-header>
                    <strong
                      ><i v-if="edit" class="cursor-drag fas fa-sort handle pr-2"></i>
                      {{ topic_lesson.title }}
                    </strong>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="m-4" v-html="topic_lesson.description"></p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>

          <!--                    Unit Summary View End-->
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { LessonPlannerMixin } from "@/mixins/LessonPlannerMixin";
import ActivityContentService from "@/core/services/lesson-planner/activity-content/ActivityContentService";
const activityContentService = new ActivityContentService();

export default {
  mixins: [LessonPlannerMixin],

  name: "LessonPlanSummary",
  computed: {
    lesson_id() {
      return this.$route.params.lesson_plan_id;
    },
    filterData() {
      return this.lesson_plan_topics.map((unit) => {
        return { lesson: unit.lessons };
      });
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  data() {
    return {
      lesson_plan_topics: [],
      lessons: [],
      active: [],
      lessonView: true,
      drawer: true,
      selectedUnit: null,
      avatar: null,
      attrs: false,
      edit: false,
      quiz: null,
      group: null,
    };
  },
  mounted() {
    this.getSingleLesson(this.lesson_id);
  },
  methods: {
    toogle() {
      this.drawer = !this.drawer;
    },
    check(item, parent) {
      if (parent) {
        this.lessonView = true;
        this.getLessonSummary(item.id);
      } else {
        this.lessonView = false;
        this.lessons = [];
        this.selectedUnit = item;
        this.lessons = item.lessons;
      }
      console.log(id, parent);
    },
    __get() {
      this.getSingleLesson(this.lesson_id);
    },

    updateLessonPlanCompletion() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            if (this.completed_topic_ids && this.completed_topic_ids.length > 1) {
              this.completed_topic_ids = this.completed_topic_ids.join(",");
            }
            if (this.completed_lesson_ids && this.completed_lesson_ids.length > 1) {
              this.completed_lesson_ids = this.completed_lesson_ids.join(",");
            }
            this.__updateLessonPlan(this.lesson_id);
          }
        },
      });
    },
    markCompleteLesson(id) {
      this.$confirm({
        message: `Mark Confirm As Complete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.markComplete(id);
          }
        },
      });
    },
    markCurrentLesson(id) {
      this.$confirm({
        message: `Confirm Set as Current?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.lesson_plan.current_lesson = id;
            this.__updateLessonPlan(this.lesson_id);
          }
        },
      });
    },

    markInCompleteLesson(id) {
      this.$confirm({
        message: `Mark Confirm As Incomplete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.markInComplete(id);
          }
        },
      });
    },

    startQuiz(activityContentId) {
      activityContentService
        .getQuizDetails(activityContentId)
        .then((response) => {
          this.quiz = response.data.quiz;
          this.$router.push({ name: "quiz-prepare", params: { quiz_id: this.quiz.id } });
        })
        .catch((error) => {
          this.$snotify.error("Problem occurred");
        });
    },
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-stepper__step {
  padding: 5px 5px !important;
  align-items: baseline !important;
}

.v-expansion-panel-header {
  padding: 12px 8px !important;
}

.v-stepper {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.v-stepper--vertical {
  padding-bottom: 0 !important;
}

.v-treeview-node__label {
  overflow-y: visible !important;
}
</style>
